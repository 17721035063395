import React from "react";
import Careerpageheader from "./Careerpageheader";
import Secondpage from "./Secondpage";
import Footer from "./Footer";
const Career = () => {
  return (
    <div>
      <Careerpageheader />
      <Secondpage />
      <Footer />
    </div>
  );
};
export default Career;
